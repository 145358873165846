<template>
  <div class="warp">
    <el-form inline size="mini">
      <el-form-item label="Period ID:">
        <el-input v-model="id" type="number" placeholder="please"></el-input>
      </el-form-item>
      <el-form-item label="Sport:">
        <select-box
          :url="STD_SEARCH_SPORT"
          :queryOrderParams="{ page: 1, size: 500 }"
          :value.sync="extraData.sportId"
          search-key="name"
          label-key="sportsName"
          value-key="id"
          :disabled="id ? true : false"
        />
      </el-form-item>
      <el-form-item label="Period Name:">
        <select-box
          :url="STD_SEARCH_MATCH_PERIOD"
          :queryOrderParams="{ page: 1, size: 100 }"
          :value.sync="extraData.id"
          search-key="standardPeriodName"
          label-key="standardPeriodName"
          value-key="standardPeriodCode"
          :disabled="id ? true : false"
        />
      </el-form-item>
      <el-form-item label="UnConfigure:">
        <el-select v-model="extraData.unConfigureLanguageType" clearable=""  placeholder="Select">
          <el-option v-for="item in langData" :key="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >Search</el-button
        >
        <el-button type="success" @click="exportExcel({libraryType})" :loading="downloding">Export</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      :data="list"
      stripe
      v-loading="loading"
      :max-height="tableHeight - fixHeight"
      @sort-change="sort"
    >
      <el-table-column
        label="Period Code"
        width="120"
        align="center"
        prop="id"
      ></el-table-column>
      <el-table-column
        label="Name"
        width="120"
        align="center"
        prop="name"
      ></el-table-column>
      <el-table-column
        v-for="item in langData"
        :key="item + 'header'"
        :label="item"
        min-width="120"
        align="center"
        prop="sportsName"
      >
        <template v-slot="{ row }">{{ row.language[item] }}</template>
      </el-table-column>
      <el-table-column
        label="Remark"
        width="120"
        align="center"
        prop="remark"
      ></el-table-column>
      <el-table-column
        label="Operation"
        min-width="100"
        align="center"
        prop="shortName"
      >
        <template v-slot="{ row }">
          <el-link
            type="primary"
            :underline="false"
            @click="
              dialogEdit({
                id: row.id,
                name: row.name,
                nameCode: row.nameCode,
                language: row.language,
                remark: row.remark
              })
            "
            >Edit</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end" style="margin-top: 20px">
      <el-pagination
        :total="total"
        :current-page="queryData.current"
        :page-size="queryData.size"
        :page-sizes="pageSizes"
        @current-change="changePage"
        @size-change="handleSizeChange"
        :layout="pageLayout"
      ></el-pagination>
    </el-row>

    <el-dialog
      :title="dialog.title"
      width="500px"
      :visible.sync="dialog.show"
      :close-on-click-modal="dialog.clickModal"
    >
      <el-form
        :model="dialogForm"
        :rules="rules"
        label-width="120px"
        size="mini"
        :ref="dialog.ref"
      >
        <el-form-item label="Period ID:" v-if="dialogForm.id">{{
          dialogForm.id
        }}</el-form-item>
        <el-form-item label="Name:" v-if="dialogForm.name">{{
          dialogForm.name
        }}</el-form-item>
        <el-form-item
          v-for="item in langData"
          :prop="`language${item}`"
          :key="item"
          :label="item + ':'"
        >
          <el-input
            v-if="dialogForm.language"
            v-model="dialogForm.language[item]"
            placeholder="Please typing in text"
            style="width:300px"
            maxlength="200"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="Remark:">
          <el-input
            v-model="dialogForm.remark"
            placeholder="Please typing in text"
            style="width:300px"
            maxlength="200"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="dialog.isLoading" @click="submit"
            >Confirm</el-button
          >
          <el-button @click="dialogHide">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryMatchPeriodInternational as getData,
  editInternational as editData
} from '@/service/standard'
import TableMixin from '@/mixins/standard/tableSelect'
export default {
  props: {
    libraryType: {
      type: String,
      default() {
        return ''
      }
    }
  },
  mixins: [TableMixin],
  data() {
    return {
      extraData: {},
      id: ''
    }
  },
  inject: ['fixHeight', 'langData', 'rules'],
  methods: {
    getData,
    editData,
    submit() {
      const language = this.dialogForm.language
      this.dialogForm.json = JSON.stringify(language)
      this.dialogForm.libraryType = this.libraryType
      this.dialogSubmit(this.dialogForm.language)
    },
    getList() {
      this.loading = true
        const extraData = {...this.fliterParams(this.extraData)}
      if (this.id) {
        delete this.extraData.id
        extraData.id = +this.id
      }
      this.getData({ ...this.queryData, ...this.fliterParams(extraData) })
        .then(res => {
          const data = res.data
          if (res.code === 0) {
            this.list = data.records || data.dataList
            this.total = data.total
            this.loading = false
          }
        })
        .catch(err => {
          this.list = []
          this.loading = false
        })
    }
  }
}
</script>
